import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  private appLanguage: string = 'Application Language';
  private languageObserver: BehaviorSubject<string>;

  constructor(private http: HttpClient) {
    this.languageObserver = new BehaviorSubject<string>(this.getAppLanguage());
  }

  /**
   * Change the language of the app to the given parameter. Without parameter given the language will be set to English.
   * @param appLanguage
   */
  public setAppLanguage(appLanguage: string = 'en') {
    localStorage.setItem(this.appLanguage, appLanguage);
    this.languageObserver.next(appLanguage);
  }

  /**
   * Get the current language as string.
   * @returns {string}
   */
  public getAppLanguage(): string {
    return localStorage.getItem(this.appLanguage);
  }

  /**
   * Get the current language as Observable string.
   * @returns {Observable<string>}
   */
  public getAppLanguageAsObservable(): Observable<string> {
    return this.languageObserver.asObservable();
  }

  /**
   * Get participant profile.
   * @returns {Observable<any>}
   */
  getProfile() {
    return this.http.get('profile');
  }

  deleteAccount() {
    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.http.post('participant/delete', [], httpOptions);
  }

  /**
   * Used to update the profile of the user with the content of the given body.
   * @param body
   * @returns {Observable<any>}
   */
  updateProfile(body): Observable<any> {
    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.http.post('participant', body, httpOptions);
  }

  /**
   * Used to unsubscribe from a study.
   * @returns 
   */
  unsubscribeFromStudy(study_id, participant_id) {
    const body = {
      study_id:  study_id,
      participant_id: participant_id
    };

    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.http.post('unsubscribe', body, httpOptions);
  }

  updatePushData(push_id, device) {
    const body = {
      push_id: push_id,
      device: device,
    };

    const httpOptions = {
      observe: 'response' as 'response'
    };

    return this.http.post('participant', body, httpOptions);
  }
}
